import * as React from 'react'

import { Layout } from 'components/global'
import { StyledButton } from 'components/shared'

const NotFoundPage = () => (
  <Layout>
    <div className="bg-black">
      <div className="w-11/12 mx-auto container py-6 text-center h-[91vh] min-h-[650px] flex flex-col items-center justify-center space-y-4">
        <h1 className="uppercase">404: Page not found</h1>
        <p className="text-2xl">You just hit a route that doesn&#39;t exist.</p>
        <StyledButton
          text="Home"
          className="text-black text-xl"
          internal
          to="/"
        />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
